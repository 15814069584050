@import "constant";
.navBarContainer{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: #fff;
  height: 50px;
  z-index: 100;
  color: #333;
  box-shadow:0px 4px 8px 0px rgba(216,224,234,0.4);
  .navBar{
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    line-height: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
  }
  .brand{
    color: #333;
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 20px;
    img{
      width: 28px;
      height: 28px;
      margin-right: 10px;
    }
  }
  .navList{
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: center;
    flex: 1;
    transition: top 0.3s ease;
    &.hideNavList{
      position: absolute;
      top: -75px;
      left: 50%;
      transform: translateX(-50%);
    }
    li{
      margin-right: 55px;
      height: 100%;
      &:last-child{
        margin-right: 0;
      }
      a{
        display: block;
        color: #333;
        font-size: 16px;
        height: 100%;
        line-height: 50px;
        position: relative;
        &:after{
          position: absolute;
          content: '';
          display: block;
          width: 40px;
          height: 3px;
          border-radius: 3px;
          background-color: transparent;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
        &.active{
          font-family: PingFangSC-Semibold;
          font-weight: 600;
          color: $theme;
          &:after{
            background-color: $theme;
          }
        }
      }
    }
  }
  .navForm{
    width: 470px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 75px;
    transition: top 0.3s ease;
    display: flex;
    background-color: #fff;
    &.showNavForm{
      top: 0;
    }
    input{
      flex: 1;
      border: none;
      font-size: 18px;
    }
    .navSearchSubmit{
      width: 50px;
      height: 75px;
      background-size: contain;
      @include bg-image('../assets/image/nav_search_active');
    }
    .navSearchClose{
      width: 50px;
      height: 75px;
      background-size: contain;
      @include bg-image('../assets/image/nav_close');
    }
  }
  .navRight{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .imgLinkContainer{
      width: 40px;
      height: 40px;
      margin: 0 10px;
    }
    .avatarImg{
      display: block;
      width: 100%;
      height: 100%;
    }
    .navSearchBtn{
      width: 50px;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      @include bg-image('../assets/image/nav_search');
      &:hover{
        @include bg-image('../assets/image/nav_search_active');
      }
      &.hide{
        display: none;
      }
    }
    .navMessageBtn{
      width: 50px;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      @include bg-image('../assets/image/nav_message');
      &:hover{
        @include bg-image('../assets/image/nav_message_active');
      }
    }
  }
  .toggleBtn{
    display: none;
    border: 1px solid $borderColor;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    img{
      width: 24px;
      height: 24px;
    }
  }
  .mblNav{
    position: absolute;
    top: 44px;
    left: 0;
    right: 0;
    margin-bottom: 0;
    height: 0;
    transition: height 0.3s ease;
    overflow: hidden;
    background-color: #fff;
    z-index: 99;
    li{
      @include borderBottom();
      height: 50px;
      margin-left: 20px;
      &:last-child{
        margin-bottom: 16px;
      }
      a{
        display: flex;
        align-items: center;
        color: $common;
        font-size: 13px;
        width: 100%;
        height: 100%;
        &.active{
          color: $theme;
        }
      }
      img{
        width: 16px;
        height: 16px;
        margin-right: 10px;
        position: relative;
        left: -1px;
      }
    }
  }
  .mblNavBar{
    height: 44px;
    line-height: 44px;
    padding: 0 10px 0 20px;
    position: relative;
    overflow: hidden;
    display: none;
    align-items: center;
    .mblLogo{
      width: 20px;
      height: 20px;
    }
    .mblBrandName{
      margin-left: 10px;
      color: #333;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      font-weight:500;
      position: relative;
      &:after{
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        content: '';
        display: table;
        width: 14px;
        height: 14px;
        @include bg-image('../assets/image/nav_mbl_bottom');
        background-repeat: no-repeat;
        background-size: contain;
        transition: all 0.3s ease;
      }
      &.isCollapsed{
        &:after{
          transform: translateY(-50%) rotate(-180deg);
        }
      }
    }
    .mblNavRight{
      flex: 1;
      display: flex;
      justify-content: space-between;
      .openAppAndAvatar{
        display: flex;
        align-items: center;
        .imgLinkContainer{
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }
        .avatarImg{
          display: block;
          width: 100%;
          height: 100%;
        }
      }
    }
    .openAppBtn{
      color: $theme;
      font-size: 13px;
    }
    .mblNavIcons{
      flex: 1;
      display: flex;
      justify-content: flex-end;
      a{
        width: 35px;
        height: 44px;
        background-size: contain;
        background-repeat: no-repeat;
        @include bg-image('../assets/image/nav_mbl_search');
        &.messageIcon{
          @include bg-image('../assets/image/nav_mbl_message');
        }
      }
    }
    .mblNavForm{
      width: calc(100% - 60px);
      position: absolute;
      left: 50px;
      top: 44px;
      height: 44px;
      transition: top 0.3s ease;
      display: flex;
      align-items: center;
      background-color: #fff;
      &.showMblForm{
        top: 0;
      }
      .inputContainer{
        flex: 1;
        height: 28px;
        line-height: 28px;
        input{
          border-radius: 14px;
          background-color: #F7F7F7;
          width: 100%;
          border: none;
          padding: 0 15px;
          color: $common;
          font-size: 12px;
        }
      }
      .btnGroup{
        width: 70px;
        display: flex;
        a{
          width: 35px;
          height: 44px;
          background-size: contain;
          background-repeat: no-repeat;
          @include bg-image('../assets/image/nav_mbl_search_active');
          &.closeIcon{
            @include bg-image('../assets/image/nav_mbl_close');
          }
        }
      }
    }
  }
  .mblNavScreen{
    position: fixed;
    top: 44px;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.7);
    display: none;
    &.show{
      display: block;
    }
  }
  .notLogin{
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    img{
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}
@media screen and (max-width:960px){
  .navBarContainer{
    .navList{
      li{
        margin-right: 30px;
      }
    }
  }
}
@media screen and (min-width: 840px) {
  .mblNav{
    display: none;
  }
}
@media screen and (max-width:840px){
  .navBarContainer{
    height: 44px;
    .mblNavBar{
      display: flex;
    }
    .navBar{
      display: none;
    }
    .navList, .navForm{
      display: none;
    }
  }
}
