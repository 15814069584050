.LoadingSpinner {
    padding-top: 30%;
    text-align: center;
    // margin-top: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    // left: 40%;
    // top: 40%;
    // height: 50;
    // width: 50;
}
.LiveLoading {
    text-align: center;
    // margin-top: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999;
    top: 30%;
    left: 50%;
    transform: translateX(-20px);
}
