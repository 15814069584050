@charset "utf-8";

@import "styles/common";
div,iframe, ul, ol, dl, dt, dd, li, dl,
h1, h2, h3, h4, p, table,th, td, input, button, select,
textarea {
  margin: 0;
  padding: 0;
  font-style: normal;
  font-size: 14px;
}
html, body{
  height: 100%;
}

*{
   box-sizing:border-box;
   touch-action: pan-y;
   outline: none;
}
ol, ul ,li {
  list-style: none;
}
img {
  border: 0;
  vertical-align:middle;
}
/* 1rem = 100px */

body {
  padding: 0;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #333 !important;
  font-family: PingFangSC-Regular, Microsoft YaHei, PingFangSC-Regular, SimSun, serif;
  background: #f0f0f0 !important;
}

input, textarea{
  outline: none;
  caret-color: $theme;
}

#root{
  max-width: 640px;
  margin: 0 auto;
  background: #fff;
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 50px;
}
@media screen and (max-width:840px){
  #root{
    padding-top: 44px;
  }
}
*{
  -webkit-tap-highlight-color: transparent;
}
a {
  color: #333;
  text-decoration: none;
  /* autoprefixer: ignore next */
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
@mixin textOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.clearfix:after{
  content: '';
  clear: both;
  display: table;
}
.toast_body{
  min-height: 40px;
}
.Toastify__toast--success{
  background-color: #4BDA69;
}
.Toastify__toast--error{
  background-color: #FF1A2E;
}