@import "../../styles/constant";
.H5Modal{
  width: 3.35rem;
  background-color: #fff;
  max-width: 640px;
  padding: 12px 16px;
  color: rgba(0,0,0,.65);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 8px rgba(0,0,0,.15);
  border-radius: 5px;
}
.H5Alert{
  width: 2.7rem;
  border-radius: 7px;
  padding-top: 15px;
  text-align: center;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  .modalAlert{
    h3{
      padding: 5px 15px 8px;
      color: #333;
      text-align: center;
      font-size: 18px;
      font-weight: normal;
    }
    .alertBody{
      padding: 0 15px 15px;
      font-size: 15px;
      line-height: 1.5;
      color: #888;
      text-align: center;
      .alertClear{
        margin-bottom: 10px;
      }
      .alertEmail{
        word-break: break-all;
      }
      .problem2{
        margin-bottom: 12px;
      }
      a{
        color: $theme;
        text-decoration: underline;
      }
    }
    .alertBottom{
      display: flex;
      align-items: center;
      height: 50px;
      overflow: hidden;
      border-top: 1px solid $borderColor;
      a{
        flex: 1;
        color: $theme;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        @include textOverflow();
        text-decoration: none;
        border-left: 1px solid $borderColor;
        &:first-child{
          border-left: none;
        }
        &:active{
          background-color: #ddd;
        }
      }
    }
  }
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}
