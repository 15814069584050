body, div,iframe, ul, ol, dl, dt, dd, li, dl,
h1, h2, h3, h4, p, table,th, td, input, button, select,
textarea {
  margin: 0;
  padding: 0;
}
body {
  font-size: 14px;
}
ol, ul ,li {
  list-style: none;
}
@mixin textOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.line-ellipsis-3{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
.opacity {
  opacity: 0;
}
.listImgCover {
  width: 60px;
  height: 50px;
  margin: 0 auto;
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    border-radius: 2px;
  }
}
.percent100 {
  height: 100%;
}
.clearfix:after{
  content: '';
  clear: both;
  display: table;
}
.mgLeft5 {
  margin-left: 5px;
}
.mgLeft10 {
  margin-left: 10px;
}
.mgRight5 {
  margin-right: 5px;
}
.mgRight10 {
  margin-right: 10px;
}
.mgTop5 {
  margin-top: 5px;
}
.mgTop10 {
  margin-top: 10px;
}
.mgTop20 {
  margin-top: 20px;
}
.mgBottom20 {
  margin-bottom: 20px;
}
.pdTop0 {
  padding-top: 0;
}
.pdRight20{
  padding-right: 20px;
}
.pdTb15 {
  padding: 15px 0;
}
.block {
  display: block
}
.font16 {
  font-size: 16px;
}
.font18 {
  font-size: 18px;
}
.flex {
  display: flex;
}
.justifyCenter {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.textLeft {
  text-align: left;
}
.calc110 {
  width: calc(100% - 110px);
}
.cursor-pointer {
  cursor: pointer;
}
$theme: #07BFE3;
$borderColor: #e8e8e8;

.introColor {
  color: #9C9C9C;
}

.numLinesOne {
  @include textOverflow();
  max-width: 300px;
}
.contentCenter {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.red {
  color: #f00;
}
.pass {
  color: limegreen;
}
.popoverContainer{
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  word-wrap: break-word;
}
