.invite-wrapped {
  background: linear-gradient(#191569, #332F9A);
  height: 100%;
  .invite-grain_bg {
    height: 100%;
    top:0;
    bottom:0;
    left:0;
    right:0;
    position: absolute;
  }
  .invite-bg {
    width: 100%;
    position: relative;
    z-index: 10;
  }
  .invite-container{
    position: relative;
    margin: -13.33% 22px;
  }
  .invite-form {
    background: #FFFFFF;
    border-radius: 8px;
    position: relative;
  }
  .invite-form-bg {
    transform: rotate(-3deg);
    background: #73B1C0;
    position: absolute;
    border-radius: 8px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .user_register {
    padding: 0;
    .login_content {
      padding: 60px 18px 12px;
      .phone_number, .vscode_message {
        box-sizing: content-box;
        height: 54px;
        margin-top: 0;
        padding: 0;
        border-bottom-color: #73B1C0;
      }
      .vscode_message {
        border-bottom: 0.5px solid #73B1C0;
        a {
          span{
            &.activeText{
              color: #73B1C0;
            }
          }
        }
        .false {
          color: #73B1C0;
          border-color: #73B1C0;
        }
      }
      .loginBtn {
        width: 209px;
        border-radius: 22px;
        margin: 32px auto 0;
        background: #ECECEC;
        color: #FFFFFF;
        font-size: 14px;
        &.active {
          background: linear-gradient(#ABD9E1, #73B1C0);
          box-shadow: 0px 4px 8px 0px rgba(65, 140, 159, 0.5);
        }
      }
    }
    .fromItem{
      border-bottom: 0.5px solid #73B1C0;
    }
    .loginBottom {
      text-align: center;
      padding-bottom: 25px;
      p, p a {
        font-size: 12px;
        color: #B2E1EC;
        margin-right:0;
      }
    }
  }
}
