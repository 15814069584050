@mixin textOverflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@mixin line-ellipsis-x($lines){
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}
@mixin disabledCopy{
  -webkit-touch-callout:none;  /*系统默认菜单被禁用*/
  -webkit-user-select:none; /*webkit浏览器*/
  -khtml-user-select:none; /*早期浏览器*/
  -moz-user-select:none;/*火狐*/
  -ms-user-select:none; /*IE10*/
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}
@mixin bg-image($url) {
  background-image: url($url + "@3x.png");
  // @media (-webkit-min-device-pixel-ratio:3),(min-device-pixel-ratio:3){
  //   background-image: url($url + "@3x.png")
  // }
}
$backGround: #f7f7f7;
$theme: #07BFE3;
$opacityTheme: #A4EAFC;
$common: #595959;
$weak: #9c9c9c;
$weaker: #c6c6c6;
$draft: #c2c2c2;
$borderColor: #e8e8e8;
$error: #FF1A2E;
$gray8C: #8C8C8C;
$f0Color: #F0F0F0;

@mixin borderBottom {
  border-bottom: 0.5px solid $borderColor;
}
@mixin pressOpacity {
  &:active{
    opacity: 0.5;
  }
}
::-webkit-input-placeholder{
  color: $weak;
}
:-moz-placeholder{
  color: $weak;
}
::-moz-placeholder{
  color: $weak;
}
:-ms-input-placeholder{
  color: $weak;
}
