.imgLinkContainer{
  width: 50px;
  height: 50px;
  position: relative;
  .avatarImg{
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  .verifiedIcon{
    position: absolute;
    right: 0;
    bottom: 0;
    width: 12px;
    height: 9px;
  }
}

