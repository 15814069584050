@import "constant";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  .modal-main {
    position:fixed;
    background: white;
    width: 3.27rem;
    max-width: 5rem;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 5px;
  }
  .closeModal{
    position: absolute;
    right: 16px;
    top: 16px;
    display: block;
    width: 24px;
    height: 24px;
    @include bg-image('../assets/image/association_post_icon_cancel');
    background-size: contain;
  }
}
.display-block {
  display: block;
}

.display-none {
  display: none;
}
.user_register {
  padding: 32px 20px;
  .login_content {
    margin-bottom: 4px;
    .loginTitle{
      display: flex;
      align-items: flex-end;
      span{
        display: block;
        width: 1px;
        height: 14px;
        background-color: $draft;
        margin: 0 16px 6px 16px;
      }
      a{
        font-size:16px;
        font-family:PingFangSC-Regular,PingFang SC;
        font-weight:400;
        color: $gray8C;
        line-height: 22px;
        margin-bottom: 2px;
       &.active{
         color: #333;
         font-size: 26px;
         font-family: PingFangSC-Medium;
         font-weight: 500;
         line-height:37px;
         margin-bottom: 0;
       }
      }
    }
    .bindDesc{
      color: $weak;
      line-height: 17px;
      font-size: 12px;
      margin-bottom: 0;
    }
    ::-webkit-input-placeholder{
      color: $weaker;
    }
    :-moz-placeholder{
      color: $weaker;
    }
    ::-moz-placeholder{
      color: $weaker;
    }
    :-ms-input-placeholder{
      color: $weaker;
    }
    .inputContainer{
      flex: 1;
    }
    input{
      color: $common;
      border: none;
      font-size: 16px;
      width: 100%;
      height: 53px;
      outline: none;
    }
  }
  .fromItem{
    margin-top: 40px;
    border-bottom: 1px solid $opacityTheme;
    display: flex;
    align-items: center;
  }
  .emailPassword.fromItem{
    margin-top: 8px;
  }
  .clearInput{
    width: 16px;
    height: 16px;
    @include bg-image('../assets/image/shape');
    background-size: contain;
    background-repeat: no-repeat;
    &.hidden{
      visibility: hidden;
    }
  }
  .phone_number {
    &>span{
      color: #73777A;
      margin-right: 15px;
      padding: 15px 0;
    }
  }
  .vscode_message {
    @include borderBottom();
    border-color: $opacityTheme;
    margin-top: 8px;
    display: flex;
    align-items: center;
    height: 54px;
    a {
      font-size: 12px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 80px;
      padding: 0 10px;
      color: $theme;
      border: 1px solid $theme;
      border-radius: 4px;
      @include pressOpacity();
      span{
        color: $weaker;
        &.activeText{
          color: $theme;
        }
      }
      &.disabled {
        color: $weaker;
        border-color: $borderColor;
        &:active{
          opacity: 1;
        }
      }
    }
  }
  .loginBtn {
    display: block;
    height: 44px;
    line-height: 44px;
    background-color: $opacityTheme;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    margin: 36px 0 8px;
    &.active{
      background-color: $theme;
      color: #fff;
      @include pressOpacity();
    }
  }
  .loginBottom{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    font-family: PingFangSC-Light;
    font-weight: 300;
    line-height: 14px;
    p{
      flex: 1;
      color: $weaker;
      font-size: 12px;
      margin-right: 36px;
      a{
        text-decoration: underline;
        color: $weaker;
      }
    }
    .problemLink{
      max-width: 75px;
      color: $theme;
      text-align: right;
    }
    a{
      @include pressOpacity();
    }
  }
}
